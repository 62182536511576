export const InitVideo = () => {
  document.querySelectorAll('.ce--video, .ce--sitepackage_video').forEach(video => {
    const videoContainer = video.querySelector<HTMLElement>('.video')
    const videoPlay = video.querySelector<HTMLElement>('.video__play')

    videoContainer && videoPlay && videoPlay.addEventListener('click', () => {
      videoContainer.classList.add('play')
    })
  })
}
