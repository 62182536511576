declare global {
  interface Window {
    /**
     * This method is executed if the user submits a successful response to recaptcha.
     *
     * The name of this method is configured at
     * typo3/public/typo3conf/ext/recaptcha/Configuration/TypoScript/setup.typoscript:22
     *
     * @param token The g-recaptcha-response token
     *
     * @see https://developers.google.com/recaptcha/docs/v3
     */
    onRecaptchaSubmit?: (token: string) => boolean
  }
}

/**
 * Register `onRecaptchaSubmit` - Callback for one element.
 */
function registerSimpleCallback(element: HTMLButtonElement) {
  window.onRecaptchaSubmit = () => {
    element.form?.submit()
    return false
  }
}

/**
 * Register `onRecaptchaSubmit` - Callback for all given elements.
 */
function registerComplexCallback(elements: NodeListOf<HTMLButtonElement>) {
  /**
   * Form Element of the form with captcha that is currently getting submitted (user clicked on submit button)
   */
  let submittingElement: HTMLButtonElement | null = null;

  elements.forEach(recaptchaSubmitButton => {
    recaptchaSubmitButton.addEventListener('click', e => {
      if (!e.target || !('form' in e.target)) {
        return
      }

      submittingElement = e.target as HTMLButtonElement
    })
  })

  window.onRecaptchaSubmit = () => {
    const element = submittingElement
    submittingElement = null
    element?.form?.submit()
    return false
  }
}

export default function InitRecaptcha() {
  const elements = document.querySelectorAll<HTMLButtonElement>("button[data-callback=\"onRecaptchaSubmit\"]")

  switch (elements.length) {
    case 0:
      break;
    case 1:
      registerSimpleCallback(elements[0]!!)
      break;
    default:
      registerComplexCallback(elements)
      break;
  }
}
