export interface ProductLiteratureResponseItem {
  fileSize: number
  filetype: string
  title: string
  thumbnailUrl: string
  downloadUrl: string
}

export type ProductLiteratureResponse = ProductLiteratureResponseItem[]

export const fetchProductLiterature = async (form: HTMLFormElement): Promise<ProductLiteratureResponse> => {
  const data = new FormData(form)

  const response = await fetch(form.action, {
    method: form.method,
    body: data
  })

  if (response.status !== 200) {
    throw new Error('Received a non 200 status from product literature endpoint. ')
  }

  return await response.json()
}
