import {fetchProductLiterature} from "./fetch-product-literature";
import {renderLiteratureSkeletonSlider, renderLiteratureSlider} from "./render-literature-slider";
import Swiper from "swiper";

export const InitProductLiterature = () => {
  document.querySelectorAll<HTMLElement>('.product-literatures-wrapper')
    .forEach((wrapper) => {

      const filterFormElement = wrapper.querySelector('.product-literatures-filter > form')

      if (!(filterFormElement instanceof HTMLFormElement)) {
        return
      }

      const thumbnailAlt: string = wrapper.dataset['thumbnailAlt'] ?? ''
      const sliderContainer = wrapper.querySelector('.slider .swiper')

      if (!(sliderContainer instanceof HTMLElement)) {
        return;
      }

      // @ts-ignore
      const swiper: Swiper = sliderContainer.swiper
      const wrapperElement: HTMLElement = wrapper.querySelector('.swiper-wrapper')!!

      filterFormElement.addEventListener('submit', (ev) => {
        ev.preventDefault()

        // How many documents should we get?
        const selectElement = filterFormElement.querySelector<HTMLSelectElement>('select.product-literatures-select')
        const expectedCount = parseInt(selectElement?.selectedOptions[0]?.dataset['count'] ?? '0')

        // render skeleton slider
        renderLiteratureSkeletonSlider(wrapperElement, expectedCount)
        swiper.update()

        // fetch the actual data
        fetchProductLiterature(filterFormElement)
          .then(r => {
            renderLiteratureSlider(r, wrapperElement, thumbnailAlt)
            swiper.update()
          })
      })
    })
}
