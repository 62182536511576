export const InitNavigationMobile = () => {

  const headerEl = document.querySelector<HTMLElement>('header.header')!!
  const navEl = headerEl.querySelector<HTMLElement>('nav.nav')!!
  const navigationWidgetEl = headerEl.querySelector<HTMLElement>('.navigation-widget')!!
  const level1TriggerEl = navEl.querySelectorAll<HTMLElement>('.nav-item--level-1.nav-item--has-children > .nav-item__link')!!
  const backToLevel1El = navEl.querySelectorAll<HTMLElement>('.nav-mega-menu-back')!!

  navigationWidgetEl.addEventListener('click', (event: Event) => {
    document.body.classList.toggle('mobile-navigation-is-open')
  })

  level1TriggerEl.forEach(element => {
    element.addEventListener('click', (event: Event) => {
      event.preventDefault();
      element.closest('.nav-item--level-1')?.classList.add('is-active')
    })
  })

  backToLevel1El.forEach(element => {
    element.addEventListener('click', (event: Event) => {
      element.closest('.nav-item--level-1')?.classList.remove('is-active')
    })
  })
}
