import type {ProductLiteratureResponse} from "./fetch-product-literature";
import {convertToReadableFileSize, TYPO3_UNITS} from "./readable-file-size";

// TODO: translate image alt
const renderSkeleton = (loading: boolean, imageSrc: string, title: string, file: string, downloadUrl: string, thumbnailAlt: string): string => `
<div class="product-literature ${loading ? 'product-literature--loading' : ""}">
  <div class="product-literature__image">
    <img src="${imageSrc}" loading="lazy" alt="${thumbnailAlt}" />
    <a class="product-literature__download icon-button" ${loading ? 'tabindex="-1" aria-disabled="true" ' : ''}
         href="${downloadUrl}">
        <i class="ph ph-download"></i>
      </a>
  </div>
  <div class="product-literature__content">
    <div class="product-literature__title">
      <strong>${title}</strong>
    </div>
    <div class="product-literature__meta">
      <span>${file}</span>
    </div>
  </div>
</div>
  `

export const renderLiteratureSlider = (literatures: ProductLiteratureResponse, wrapper: HTMLElement, thumbnailAlt: string) => {
  // remove all children
  const range = document.createRange()
  range.selectNodeContents(wrapper)
  range.deleteContents()

  // render literatures
  for (const literature of literatures) {
    const fileSize = convertToReadableFileSize(literature.fileSize, 2, TYPO3_UNITS)
    const e = document.createElement('div')
    e.classList.add('swiper-slide')

    e.innerHTML = renderSkeleton(
      false,
      literature.thumbnailUrl,
      literature.title,
      `${literature.filetype} / ${fileSize}`,
      literature.downloadUrl,
      thumbnailAlt
    )

    wrapper.appendChild(e)
  }
}

export const renderLiteratureSkeletonSlider = (wrapper: HTMLElement, count: number) => {
  // remove all children
  const range = document.createRange()
  range.selectNodeContents(wrapper)
  range.deleteContents()

  // render skeletons
  for (let i = 0; i < count; i++) {
    const e = document.createElement('div')
    e.classList.add('swiper-slide')

    e.innerHTML = renderSkeleton(true, '', '', '', '', '')

    wrapper.appendChild(e)
  }
}
