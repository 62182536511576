//import InitSwiper from './swiper'
import InitGLightBox from './glightbox'
import InitMasonry from './masonry'
import InitAnchors from './anchors'
import { InitContentFadeIn } from './content-fade-in'
import { InitHeaderSticky } from './header/header-sticky'
import InitRecaptcha from "./recaptcha";
import { InitProductLiterature } from "./product-literature/init";
import { InitPolyfills } from "./polyfills";
import { InitHeaderSearch } from "./header/header-search";
import { InitRegionWidget } from "./header/region-widget";
import { InitVideo } from './video'
import { InitChoices } from './choices'
import { InitNavigationMobile } from './navigation/navigation-mobile'

window.addEventListener('load', () => {
  InitPolyfills()
  InitGLightBox()
  InitMasonry()
  InitAnchors()
  InitRecaptcha()

  // new from here
  InitContentFadeIn()
  InitHeaderSticky()
  InitHeaderSearch()
  InitRegionWidget()
  InitVideo()
  InitChoices()

  window.innerWidth <= 1024 && InitNavigationMobile()

  window.addEventListener('resize', function (event) {
    window.innerWidth <= 1024 && InitNavigationMobile()
  }, true)

  // TODO: maybe only for detail pages
  InitProductLiterature()
})
