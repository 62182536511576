export const InitHeaderSearch = () => {
  const headerEl = document.querySelector<HTMLElement>('.header')!!
  const searchWidgetEl = document.querySelector<HTMLElement>('.search-widget--desktop')!!
  const searchWidgetMobileEl = document.querySelector<HTMLElement>('.search-widget--mobile')!!
  const quickSearchInputEl = document.querySelector<HTMLElement>('.quicksearch-input')!!

  // for later implementation of search flyout
  // const searchFlyoutEl = document.querySelector<HTMLElement>('.search-flyout')!!
  // const searchFlyoutCloseEl = searchFlyoutEl.querySelector<HTMLElement>('.search-flyout-close')!!

  searchWidgetEl.addEventListener('click', (event: Event) => {
    headerEl.classList.toggle('search-is-open')

    // wait for transition duration with focus, otherwise stuttering
    setTimeout(() => {
      headerEl.classList.contains('search-is-open') && quickSearchInputEl.focus()
    }, 200)
  })

  searchWidgetMobileEl.addEventListener('click', (event: Event) => {
    headerEl.classList.toggle('search-is-open-mobile')

    // wait for transition duration with focus, otherwise stuttering
    setTimeout(() => {
      headerEl.classList.contains('search-is-open') && quickSearchInputEl.focus()
    }, 200)
  })

  // for later implementation of search flyout
  // searchFlyoutCloseEl.addEventListener('click', (event: Event) => {
  //   headerEl.classList.remove('search-is-open')
  // })

  document.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key == "Escape") {
      headerEl.classList.remove('search-is-open')
    }
  })

}
