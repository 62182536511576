const msg = (msg: string) => `Failed to execute 'requestSubmit' on 'HTMLFormElement': ${msg}.`

const validateSubmitter = (submitter?: any, form?: HTMLFormElement) => {
  if (!(submitter instanceof HTMLInputElement)) {
    throw new TypeError(msg("parameter 1 is not of type 'HTMLElement'"))
  }

  if (submitter.type !== "submit") {
    throw new TypeError(msg("The specified element is not a submit button"))
  }

  if (submitter.form !== form) {
    throw new DOMException(msg("The specified element is not owned by this form element"), "NotFoundError")
  }
}

/**
 * Polyfill for the HTMLFormElement::requestSubmit function.
 *
 * @see https://caniuse.com/mdn-api_htmlformelement_requestsubmit
 */
export const InitFormRequestSubmitPolyfill = () => {
  HTMLFormElement.prototype.requestSubmit = function (submitter) {
    if (submitter) {
      validateSubmitter(submitter, this)
      submitter.click()
    } else {
      const tempSubmitter: HTMLInputElement = document.createElement('input')
      tempSubmitter.type = "submit"
      tempSubmitter.hidden = true
      this.appendChild(tempSubmitter)
      tempSubmitter.click()
      this.removeChild(tempSubmitter)
    }
  }
}

export const isFormRequestSubmitPolyfillRequired = (): boolean => typeof HTMLFormElement.prototype.requestSubmit !== "function"
