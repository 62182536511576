// different types of units
export const TYPO3_UNITS = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
export const DECIMAL_UNITS = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
export const BINARY_UNITS = ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]

export const DECIMAL_UNIT_CONVERSION = 1000
export const BINARY_UNIT_CONVERSION = 1024


/**
 * Converts a number of bytes into a readable file size.
 *
 * @param bytes The number of bytes in bytes. Cannot be negative.
 * @param digits The amount of digits shown.
 * @param units
 * @param unitConversionNumber
 * @param radix
 */
export const convertToReadableFileSize = (
  bytes: number,
  digits = 0,
  units = BINARY_UNITS,
  unitConversionNumber = BINARY_UNIT_CONVERSION,
  radix = 10
): string => {
  if (Number.isNaN(bytes) || bytes < 0) {
    throw new TypeError('The number of bytes cannot be negative or NaN. ')
  }

  let unitPtr = 0

  while (bytes >= Math.pow(unitConversionNumber, unitPtr + 1) && unitPtr < units.length - 1) {
    unitPtr++
  }

  const rawNumberInUnit = bytes / Math.pow(unitConversionNumber, unitPtr)

  const rounder = Math.pow(radix, digits)
  const roundNumberInUnit = Math.round((rawNumberInUnit + Number.EPSILON) * rounder) / rounder

  const unitStr = units[unitPtr]

  return `${roundNumberInUnit.toString(radix)} ${unitStr}`
}
