import Masonry from "masonry-layout"

export default function InitMasonry() {
  document.querySelectorAll<HTMLElement>('.masonry').forEach(wrapper => {
    const masonry = new Masonry(wrapper, {
      itemSelector: '.masonry-item',
      gutter: 15
    })

    const calculateLayout = () => masonry.layout && masonry.layout()

    window.addEventListener('load', () => calculateLayout())
    wrapper.querySelectorAll('picture img').forEach(() => {
      calculateLayout()
    })
  })
}
