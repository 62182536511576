/// <reference path="./glightbox.d.ts" />
// @ts-ignore
import GLightBox from 'glightbox'

export default function InitGLightBox() {
  /**
   * Standard glightbox behavior of .glightbox divs
   */
  GLightBox()

  /**
   * Standard glightbox behavior of .glightbox-popup divs
   */
  const lightboxPopup = GLightBox({
    selector: '.glightbox-popup',
    skin: 'clean glightbox-popup',
    keyboardNavigation: false,
    touchNavigation: false,
    draggable: false,
    zoomable: false,
  })

  lightboxPopup.on('open', function () {
    document.querySelectorAll<HTMLAnchorElement>('a.hide-popup').forEach(anchor => anchor.addEventListener('load', (e) => {
      e.preventDefault()
      lightboxPopup.on('close', function () {
        window.location.assign(anchor.href)
      })
      lightboxPopup.close()
    }))
  })
}
